import React, {useEffect, useState} from 'react';
import {useQueryParam, StringParam} from "use-query-params";
import {navigate} from 'gatsby';
import moment from 'moment-timezone';
import CardThread from '@components/CardThread/CardThread';
import {getMostTrendingThread} from '@repository/ThreadRepository';
import IcNotFound from '@icons/ic-not-found.png';

function MostTrending() {
  const [categoryId, setCategoryId] = useQueryParam("categoryid", StringParam);
  const [categoryTitle, setCategoryTitle] = useQueryParam("categorytitle", StringParam);

  const [isLoadData, setIsLoadData] = useState(true);
  const [mostTrendings, setMostTrendings] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [lastPage, setLastPage] = useState(1);
  const [pageNumbers, setPageNumbers] = useState([]);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }, []);

  useEffect(() => {
    handleMostTrendingThread();
  }, [currentPage]);

  useEffect(() => {
    generateAndSetPageNumbers();
  }, [lastPage, currentPage]);

  const handleMostTrendingThread = () => {
    let _categoryId = categoryId ? categoryId : null;
    setIsLoadData(true);

    let countryCode = null;
    let localStorageCountryString = localStorage.getItem('country');
    if (localStorageCountryString !== null) {
      let localStorageCountryJSON = JSON.parse(localStorageCountryString);
      countryCode = localStorageCountryJSON.alpha3Code;
    }

    getMostTrendingThread(countryCode, _categoryId, currentPage)
      .then((res) => {
        setMostTrendings(res.data.data);
        setLastPage(res.data.meta.last_page);
        setIsLoadData(false);
      })
      .catch((err) => {
        setIsLoadData(false);
        console.log(err.response);
      });
  };

  const generateAndSetPageNumbers = () => {
    if (lastPage == 1) {
      setPageNumbers([]);
      return;
    }

    let firstPageNumber = 1;
    if (lastPage <= 5) {
      firstPageNumber = 1;
    } if (currentPage - 2 <= 0) {
      firstPageNumber = 1;
    } else if (currentPage + 2 >= lastPage) {
      firstPageNumber = lastPage - 4;
    } else {
      firstPageNumber = currentPage - 2;
    }

    if (firstPageNumber < 1) firstPageNumber = 1;

    let lastPageNumber = 5;

    if (lastPage <= 5) {
      lastPageNumber = lastPage;
    } else {
      lastPageNumber = firstPageNumber + 4;
    }

    let pageNumbersTemp = [];
    for (let i = firstPageNumber; i <= lastPageNumber; i++) {
      pageNumbersTemp.push(i);
    }
    setPageNumbers(pageNumbersTemp);
  }

  return (
    <div className="container d-flex flex-column flex-grow-1 py-5">
      {categoryId && categoryTitle && (
        <div className="d-flex asap-body mb-3">
          <div className="text-azure-blue cursor-pointer" onClick={() => navigate('/browse')}>Categories</div>
          <div className="text-harbor-gray mx-2">/</div>
          <div
            className="text-azure-blue cursor-pointer"
            onClick={() => navigate(`/browse?categoryid=${categoryId}&categorytitle=${categoryTitle}`)}>
            {categoryTitle}
          </div>
          <div className="text-harbor-gray mx-2">/</div>
          <div className="text-coal-black">Trending Threads</div>
        </div>
      )}
      <div className="dosis-title-1 text-philadelphia-blue mb-3">
        Most Trending Threads
      </div>
      {isLoadData ? (
        <div className="mdi mdi-loading mdi-spin mdi-48px mx-auto py-5 my-5" />
      ): (
        mostTrendings.length > 0 ? (
          <>
            <div className="row">
              {mostTrendings.map((thread) => (
                <div className="col-xl-6">
                  <CardThread
                    id={thread._id}
                    key={thread._id}
                    className="mb-3"
                    countryFlagImage={thread.user.country.flag}
                    image={thread.image_url}
                    name={thread.user.fullname}
                    topic={thread.category.title}
                    title={thread.title}
                    time={moment.unix(thread.created_at).fromNow()}
                    viewerCount={thread.view_count}
                    chatCount={thread.replies_count}
                    upVoteCount={thread.votes_up_count}
                    downVoteCount={thread.votes_down_count}
                  />
                </div>
              ))}
            </div>
            <div className={`align-items-center justify-content-center pt-4 ${lastPage <= 1 ? "d-none" : "d-flex"}`}>
              <div
                className={`mdi mdi-chevron-double-left mdi-18px cursor-pointer mr-2 ${currentPage === 1 ? "text-abalone-gray" : "text-philadelphia-blue"}`}
                onClick={() => {
                  if (currentPage !== 1) {
                    setCurrentPage(prevValue => prevValue - 1);
                  }
                }} />
              {pageNumbers.map((pageNumber, index) => {
                return (
                  <div
                    key={`number-${index}`}
                    className={
                      "asap-body mx-2 " +
                      (pageNumber == currentPage ? "text-philadelphia-blue font-weight-bold" : "text-iron-gray cursor-pointer")
                    }
                    onClick={() => {
                      if (pageNumber == currentPage) return;
                      setCurrentPage(pageNumber);
                    }}>
                    {pageNumber}
                  </div>
                );
              })}
              <div
                className={`mdi mdi-chevron-double-right mdi-18px cursor-pointer mr-2 ${currentPage === lastPage ? "text-abalone-gray" : "text-philadelphia-blue"}`}
                onClick={() => {
                  if (currentPage !== lastPage) {
                    setCurrentPage(prevValue => prevValue + 1);
                  }
                }}
              />
            </div>
          </>
        ) : (
          <div className="d-flex flex-column justify-content-center align-items-center py-5 my-3">
            <img src={IcNotFound} className="mb-3" />
            <div className="dosis-title-1 text-philadelphia-blue">Thread Not Found</div>
          </div>
        )
      )}
    </div>
  );
}

export default MostTrending;
